import React from 'react'
import MainCss from '../css/Main.module.css'
import ErrorCss from '../css/Error.module.css'
import pic from '../img/beatlestest.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMusic } from '@fortawesome/free-solid-svg-icons'


function Error() {
	return (
		<div className={MainCss.content}>
			<div className={ErrorCss.message}>
				<h1>Page not found 💁‍♂️ </h1>
				<div className={ErrorCss.pic}>
					<div className={ErrorCss.music}>
					<FontAwesomeIcon icon={faMusic} className={ErrorCss.icon}/>
					<FontAwesomeIcon icon={faMusic} className={ErrorCss.icon2}/>
					<FontAwesomeIcon icon={faMusic} className={ErrorCss.icon}/>
					<FontAwesomeIcon icon={faMusic} className={ErrorCss.icon2}/>
				</div>
					<img alt='beatles get back'  src={pic}></img>
				</div>
			</div>
		</div>
	)
}

export default Error