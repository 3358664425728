import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { UserAuthContextProvider } from './context/UserAuthContext';
import Home from './pages/Home';
import Login from './pages/Login';
import Console from './pages/Console';
import Error from './pages/Error';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Documenten from './pages/Documenten';
import ProtectedRoute from './components/ProtectedRoute';
import MainCss from './css/Main.module.css'

function App() {

  return (
   <Router>
      <div className={MainCss.App}>
        <UserAuthContextProvider>
            <Navbar />
              <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/login' element={<Login />} />
                <Route path='/documenten' element={<ProtectedRoute><Documenten /></ProtectedRoute>} />
                <Route path='/dashboard' element={<ProtectedRoute><Console /></ProtectedRoute>} />
                <Route path='*' element={<Error />} />
              </Routes>
            <Footer />
          </UserAuthContextProvider>
      </div>
   </Router>
  )
}

export default App;
