import React from 'react'
import FootCss from '../css/Footer.module.css'


function Footer() {
	return (
		<div className={FootCss.bg}>
			<div className={FootCss.pad}>
				<div className={FootCss.left}>
					<h3>Festina Lente Capital</h3>			
				</div>
				<div className={FootCss.right}>
					<h3>Amsterdam</h3>
				</div>
			</div>
		</div>
	)
}

export default Footer