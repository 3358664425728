var today = new Date();

export function color(ret) {
  if (ret >= 0) {
    return '#27AE60'
  }
  else {
    return '#FF5000'
  }
}

export function getColor(mouseOver, baseret, ret) {
  if (mouseOver) {
    return color(baseret);
  } else {
    return color(ret);
  }
}

export function retAmount(deposit, total) {
  const ret = ((total - deposit) / deposit * 100).toFixed(1)
  if (ret >= 0) {
    return "+" + ret
  } else {
    return ret
  }
}

export function posCheck(val) {
  if (val >= 0) {
    return "+€" + val
  } else {
    return "-€" + Math.abs(val)
  }
}

export function formatnl(date) {
  var options = {weekday: 'long', hour:'numeric', minute:'numeric'}
  var result = new Date(date).toLocaleDateString("nl-NL", options)
  return result
} 

export function message() {
  var time = today.getHours()

  if (time > 17 && time <= 23) {
    return 'Goedenavond,'
  } else if (time > 11 && time <= 17) {
    return 'Goedemiddag,'
  } else if (time > 5 && time <= 11) {
    return 'Goedemorgen,'
  } else {
    return 'Goedenacht,'
  }
}

export function uren(time) {
  var diff =  Math.trunc((today - time) / 3600000)
  return diff
}

export var formatter = new Intl.NumberFormat('us-US', {
  style: 'currency',
  currency: 'EUR',
});