import { useState, useEffect } from 'react';
import { db } from '../db_conn/firebase.js';
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { useUserAuth } from '../context/UserAuthContext'
import { getColor, formatnl, uren, retAmount, posCheck } from './Func'
import PctChart from './PctChart';
import ConsoleCss from '../css/Console.module.css';
import { doc } from "firebase/firestore";
import AnimatingNumber from './Number'
import { Line } from 'react-chartjs-2';
import { Chart } from 'chart.js/auto';

function Dash() {

	const { user } = useUserAuth() 
  const Collection = collection(db, "users", user.uid, "stock_info");
	const q = query(Collection, orderBy("date", "asc"));
	const NameRefs = doc(db, "users", user.uid)
	const spRef = doc(db, 'fund_stats', 'ctIzW2BoC7ytAljKNnPI')
	const [sps, setSps] = useState([]);
	const [total, setTotal] = useState([])
	const [base, setBase] = useState([])
  const [slot, setSlot] = useState([])
	const [deposit, setDeposit] = useState([])
	const [bdep, setBdep] = useState([])
	const [price, setPrice] = useState([])
	const [qty, setQTY] = useState([])
	const [mouseOver, setMouseOver] = useState(false);
	const start_year = 63.53200783530049

  useEffect(() => {
    
    onSnapshot(q, snapshot => {
      setSps(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    })

		onSnapshot(NameRefs, (doc) => {
      setTotal(doc.data().value)
			setBase(doc.data().value)
			setDeposit(doc.data().deposited)
			setBdep(doc.data().deposited)
			setQTY(doc.data().stock)
			
    })

		onSnapshot(spRef, (dc) => {
      setSlot(dc.data().date.toDate())
			setPrice(dc.data().sp)
    })
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

	const handleMouseOver = () => {
		setMouseOver(true);
	}

	const handleMouseLeave = () => {
		setMouseOver(false);
	}

  const state = {
    labels: sps.map((data) => data.date.toDate()),
    datasets: [
			{ label: 'Value',
				borderColor:  getColor(mouseOver, retAmount(bdep, base), retAmount(deposit, total)) ,
				backgroundColor: getColor(mouseOver, retAmount(bdep, base), retAmount(deposit, total)),
				borderWidth: 2,
				data: sps.map((data) => data.value),
				tension: 0.3,
				pointRadius: 0,
				pointBorderColor: '#1E1E1E',
				hoverBorderWidth: 2.5, 
				pointHoverRadius: 6,
			},
			{	label: 'Deposits',
				borderColor: 'grey',
				backgroundColor: '#E0E0E0',
				borderWidth: 1.8,
				data: sps.map((data) => data.deposited),
				pointRadius: 0,
				pointHoverRadius: 0,
				borderDash: [5, 5]}
    ]
  }

	const plugin = {
    id: 'verticalLiner',
    afterInit: (chart) => {
      chart.verticalLiner = {}
    },
    afterEvent: (chart, args) => {
			const {inChartArea} = args
			chart.verticalLiner = {draw: inChartArea}
    },
    beforeTooltipDraw: (chart, args) => {
			const {draw} = chart.verticalLiner
			if (!draw) return

			const {ctx} = chart
			const {top, bottom} = chart.chartArea
			const {tooltip} = args
			const x = tooltip?.caretX
			if (!x) return

			ctx.save()     
			ctx.beginPath();
			ctx.moveTo(x, top);
			ctx.lineTo(x, bottom);
			ctx.lineWidth = 1;
			ctx.strokeStyle = 'grey';
			ctx.stroke();
			ctx.restore();
		},
	}

	const options = {
		animation: false,
		responsive: true,
		clip:false,
		plugins:{
			legend: {
				display: false
			},
			tooltip:{
				yAlign: 'bottom',
				titleColor: '#FDF1E6',
				backgroundColor: 'rgba(0, 0, 0, 0)',
				bodyColor: 'rgba(0, 0, 0, 0)',
				displayColors: false,
				callbacks: {
					title: context => {
						setBase(context[0].raw)
						setBdep(context[1].raw)
						const d = new Date(context[0].label.split(",")[0] + context[0].label.split(",")[1])
						const formattedDate = d.toLocaleString([], {
							year: '2-digit',
							month: 'short',
							day: 'numeric',
						})
						return formattedDate
					}
				},
			}
		},
		maintainAspectRatio: false,
		interaction: {
			mode: 'index',
			intersect: false,
		},
		layout:{
			padding:{
				top: 5,
				bottom: 5,
				right: 5,
				left: 5,
			}
		},
		scales:{
			y:{
				display: false,
			},
			x:{
				display: false,
				type: 'time',
				ticks:{
					display: false,
					displayFormats: {
						quarter: 'MMM YYYY'
					},
					color: 'grey'
				},
			 }
		}
	}

	return (
		<div>
			<div className={ConsoleCss.stats}>
				<div className={ConsoleCss.flexing}>
					<div>
						{ mouseOver ? <AnimatingNumber value={base}/> : <AnimatingNumber value={total}/> }
					</div>
				</div>
					<div className={ConsoleCss.return}>
						<div style={{color: getColor(mouseOver, retAmount(bdep, base), retAmount(deposit, total))}}>{ mouseOver ? (<h4>{posCheck((base - bdep).toFixed(2))} ({retAmount(bdep, base)}%)</h4>) : 
						(<h4>{posCheck((total - deposit).toFixed(2))} ({retAmount(deposit, total)}%)</h4>)}</div>
							<h5>Sinds start</h5>
						</div>
						<div className={ConsoleCss.return}>
						<div style={{color: getColor(mouseOver, retAmount(start_year, price), retAmount(start_year, price))}}>{ 
						(<h4>{posCheck(((price - start_year) * qty).toFixed(2))} ({retAmount(start_year, price)}%)</h4>)}</div>
							<h5>YTD</h5>
						</div>
					<h5>Laatste update: { formatnl(slot) }</h5>
				</div>
			<div className={ConsoleCss.flexbox}>
			<div className={ConsoleCss.boxl} onTouchMove={handleMouseOver}
                                       onTouchEnd={handleMouseLeave} 
																			 onTouchEndCapture={handleMouseLeave}
																			 onTouchCancel={handleMouseLeave}
																			 onTouchCancelCapture={handleMouseLeave}
																			 onMouseEnter={handleMouseOver} 
																			 onMouseLeave={handleMouseLeave} >
					<Line
							data={state}
							plugins={[plugin]}
							options={options}
					/>
			</div>
			</div>
		</div>
	)
}

export default Dash