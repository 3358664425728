import MainCss from '../css/Main.module.css'
import ConsoleCss from '../css/Console.module.css';
import Dash from '../components/Dash';
import Widgets from '../components/Widgets'
import { useUserAuth } from '../context/UserAuthContext'
import { db } from '../db_conn/firebase.js';
import { useState, useEffect } from 'react';
import { onSnapshot, doc } from "firebase/firestore";
import { message } from '../components/Func'
import GoogleTreemap, {} from '../components/tree'

function Console() {

  const [name, setName] = useState('')
  const { user } = useUserAuth()
  const NameRef = doc(db, "users", user.uid)

	useEffect(() => {

		onSnapshot(NameRef, (doc) => {
      setName(doc.data().name)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={MainCss.content}>
      <div className={ConsoleCss.message}>
        <h1>{ message() } { name } </h1>
      </div>
      <Dash/>
      <GoogleTreemap />
    </div>
  );
}

export default Console;
