import React, { useState } from 'react'
import MainCss from '../css/Main.module.css'
import LoginCss from '../css/Login.module.css'
import { useUserAuth } from '../context/UserAuthContext'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faLockOpen} from '@fortawesome/free-solid-svg-icons'

function Login() {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const { logIn } = useUserAuth()
	const [error, setError] = useState('')
	const navigate = useNavigate()

	const [passwordShown, setPasswordShown] = useState(false);
	const [lock, setLock] = useState(faLock)

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
		if (passwordShown === false) {
			setLock(faLockOpen)
		} else {
			setLock(faLock)
		}
  };

	const handleSubmit = async (e) => {
		e.preventDefault()
		setError('')
		try {
			await logIn(email, password)
			navigate('/dashboard')
		} catch (err) {
			setError('Email/wachtwoord combinatie is onjuist')
		} 
	}

	return (
		<div className={MainCss.content}>
		<div className={LoginCss.center}>
			<div className={LoginCss.block}>
				<h1 className={LoginCss.title}>Investor Login</h1>
				<div className={LoginCss.row}>
					<input placeholder='Email...' 
						onChange={(e) => setEmail(e.target.value)}
						type={'email'}/>
				</div>
				<div className={LoginCss.row}>
					<input placeholder='Password...' type={passwordShown ? "text" : "password"}
						onChange={(e) => setPassword(e.target.value)}/>	
					<button className={LoginCss.icon} onClick={togglePassword} style={{marginRight: '0.5rem'}}><FontAwesomeIcon icon={lock} /></button>
				</div>
					<button onClick={handleSubmit} className={LoginCss.submit}>Submit</button>
					<div className={LoginCss.error}> { error } </div>
			</div>
		</div>
	</div>
	)
}

export default Login