import React, { useEffect, useRef } from 'react';
import { select, hierarchy, treemap, scaleOrdinal, schemeCategory10 } from 'd3';
import chroma from 'chroma-js'; // Import the chroma library
import { db } from '../db_conn/firebase.js';
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import TreeCss from '../css/Tree.module.css'
import * as d3 from 'd3';

function adjustBrightness(hexColor, factor) {
  return chroma(hexColor).brighten(factor).hex();
}

const GoogleTreemap = () => {
  const svgRef = useRef();
  const containerRef = useRef();
  const userCollectionRef = collection(db, "pie_chart");
  const q = query(userCollectionRef, orderBy("percent", "desc"));

  useEffect(() => {
    const renderTreemap = (width, height) => {
      const svg = select(svgRef.current);
      svg.selectAll('*').remove(); // Clear previous rendering
    
      // Fetch the data
      onSnapshot(q, snapshot => {
        const stats = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    
        const data = {
          name: "Stocks",
          children: stats.map(stat => ({ name: stat.ticker, value: stat.percent, color:stat.color, country:stat.country })),
        };


        //const height = 275; // You can change this value

    
        //const height = 275; // You can change this value

        // Convert data to hierarchical data
        const root = hierarchy(data)
          .sum(d => d.value)
          .sort((a, b) => b.value - a.value);
    
        // Initialize the treemap
        treemap()
          .size([width, height])
          .padding(1)(root);
    
        // Find smallest rectangle dimensions
        let minWidth = Infinity;
        let minHeight = Infinity;
    
        root.leaves().forEach(leaf => {
          const rectWidth = leaf.x1 - leaf.x0;
          const rectHeight = leaf.y1 - leaf.y0;
          minWidth = Math.min(minWidth, rectWidth);
          minHeight = Math.min(minHeight, rectHeight);
        });
    
        // Set a font size based on the smallest rectangle
        const fontSize = Math.min(minWidth, minHeight) / 3.2; // Adjust divisor as needed
    
        // Bind data to the rectangles
        const leaf = svg
          .selectAll('g')
          .data(root.leaves())
          .enter()
          .append('g')
          .attr('transform', d => `translate(${d.x0},${d.y0})`)
          .on('mouseover', function () {
            select(this)
              .transition()
              .duration(200)
              .attr('transform', d => `translate(${d.x0 - 4},${d.y0 - 4})`)
              .select('rect')
              .attr('width', d => d.x1 - d.x0 + 4)
              .attr('height', d => d.y1 - d.y0 + 4);
          })
          .on('mouseout', function () {
            select(this)
              .transition()
              .duration(200)
              .attr('transform', d => `translate(${d.x0},${d.y0})`)
              .select('rect')
              .attr('width', d => d.x1 - d.x0)
              .attr('height', d => d.y1 - d.y0);
          });
    
        // Create the rectangles
        leaf
          .append('rect')
          .attr('width', d => d.x1 - d.x0)
          .attr('height', d => d.y1 - d.y0)
          .style('fill', '#FDF1E6')
          .attr('rx', 10)
          .attr('ry', 10)
          .style('stroke', '#1E1E1E')
          .style('stroke-width', '4px')
          .style('cursor', 'auto')
          .classed('rectangle', true);
    
        // Add text and percentages within the rectangles
        leaf
          .append('text')
          .attr('x', (d) => (d.x1 - d.x0) / 2) // Center horizontally
          .attr('y', (d) => (d.y1 - d.y0) / 2) // Center vertically
          .attr('dy', '-0.25em') // Adjust vertical alignment
          .attr('text-anchor', 'middle')
          .style('fill', '#1E1E1E')
          .style('font-size', fontSize + 'px') // Set consistent font size
          .style('pointer-events', 'none')
          .text(d => d.data.name)
          .append('tspan')
          .attr('x', (d) => (d.x1 - d.x0) / 2)
          .attr('dy', '1.2em')
          .text(d => `${d.data.value.toFixed(1)}%`);
      });
    };
    
        

    const handleResize = () => {
      const width = containerRef.current.offsetWidth;
      const height = containerRef.current.offsetHeight;
      renderTreemap(width, height);
    };

    window.addEventListener('resize', handleResize);

    // Initial render
    handleResize();

    // Clean up event listener on unmount
    return () => window.removeEventListener('resize', handleResize);

  }, []);

  return (
    <div className={TreeCss.test} ref={containerRef}>
      <svg className={TreeCss.svgtest}ref={svgRef}></svg>
    </div>
  );
};

export default GoogleTreemap;
