import { useState, useEffect } from 'react';
import { db } from '../db_conn/firebase.js';
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { Doughnut } from 'react-chartjs-2';

function PctChart() {
  
	const [stats, setStats] = useState([]);
  const userCollectionRef = collection(db, "pie_chart");
  const q = query(userCollectionRef, orderBy("percent", "desc"));

  useEffect(() => {
    
    onSnapshot(q, snapshot => {
        setStats(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    })
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const pie = {
    labels: stats.map((data) => data.ticker),
    datasets: [
      {
        backgroundColor: stats.map((data) => data.color),
        borderColor: '#000000',
        borderWidth: 6,
        data: stats.map((data) => data.percent),
        cutout: '68%',
        hoverOffset: 30,
        hoverBorderWidth: 0
      }
    ]
  }

  const hoverlabel = {
    afterDraw: (chart) => {
      const { ctx, chartArea: { width, height } } = chart
      ctx.save()

      if (chart._active.length > 0) {
        const textLabel = chart.config.data.labels[chart._active[0].index]
        const numberLabel = Number(chart.config.data.datasets[0].data[chart._active[0].index].toFixed(1))
        ctx.font = '18px Raleway, sans-serif'
        ctx.textAlign = 'center'
        ctx.fillStyle = '#f8f9fd'
        const textLabelHeight = 18
        ctx.fillText(textLabel, width / 2 + 26, height / 2 + 6 - textLabelHeight / 2)
        ctx.fillText(`${numberLabel}%`, width / 2 + 26, height / 2 + 6 + textLabelHeight / 2)
      }
    },
  }

  const options = {
    plugins:{
      legend:{
        display: false
      },
      tooltip: {
        enabled: false
      },                 
    },
    layout:{
      padding: {
        right: 25,
        left: 25
      }
    },
    radius: '100%'
  }
	
	return (
		<Doughnut 
			data={pie}
			options={options}
      plugins={[hoverlabel]}
		/>
	)
}

export default PctChart