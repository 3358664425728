import React from 'react'
import HomeCss from '../css/Home.module.css'
import { storage } from '../db_conn/firebase.js';
import { getDownloadURL, ref} from 'firebase/storage'
import MainCss from '../css/Main.module.css'

function Documenten() {

	const handleClick = value => () => {
	const name = value + '.pdf'
	const starsRef = ref(storage, name);
		getDownloadURL(starsRef)
		.then((url) => {
			window.open(url, "_blank")
		})
		.catch((error) => {
			console.error(error)
  		alert('There was an error trying to download the file. Please try again later.')
		});
		}

	return (
		<div className={MainCss.content}>
			<div className={HomeCss.doc}>
				<h1>Documenten</h1>
				<div className={HomeCss.report}>
					<h2>2022</h2>
				</div>
				<div className={HomeCss.report}>
					<h2>2021</h2>
					<h4 onClick={handleClick('2021/jaaroverzicht')}>Jaaroverzicht</h4>
				</div>
				<div className={HomeCss.report}>
					<h2>2020</h2>
					<h4 onClick={handleClick('2020/jaaroverzicht')}>Jaaroverzicht</h4>
				</div>
			</div>
		</div>
	)
}

export default Documenten