import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { usePrevious } from "../hooks/in";
import NumberCss from '../css/Number.module.css'

function formatForDisplay(number = 0) {
  return parseFloat(Math.max(number, 0)).toFixed(2).split("").reverse();
}

function DecimalColumn() {
  return (
    <div>
      <span>.</span>
    </div>
  );
}

function NumberColumn({ digit, delta }) {
  const [position, setPosition] = useState(0);
  const [animationClass, setAnimationClass] = useState(null);
  const previousDigit = usePrevious(digit);
  const columnContainer = useRef();

  const setColumnToNumber = (number) => {
    setPosition(columnContainer.current.clientHeight * parseInt(number, 10));
  };

  useEffect(() => {
    setAnimationClass(previousDigit !== digit ? delta : "");
  }, [previousDigit]); // Include all dependencies here
  

  useEffect(() => setColumnToNumber(digit), [digit]);

  return (
    <div className={NumberCss.tickercolumncontainer} ref={columnContainer}>
      <motion.div
        transition={{ duration: 0.25 }}
        animate={{ y: position }}
        className={`${NumberCss.tickercolumn} ${animationClass}`}
        onAnimationComplete={() => setAnimationClass("")}
      >
        {[9, 8, 7, 6, 5, 4, 3, 2, 1, 0].map((num) => (
          <div key={num} className={NumberCss.tickerdigit}>
            <span>{num}</span>
          </div>
        ))}
      </motion.div>
      <span className={NumberCss.numberplaceholder}>0</span>
    </div>
  );
}

export default function AnimatingNumber({ value }) {
  const numArray = formatForDisplay(value);
  const previousNumber = usePrevious(value);

  let delta = null;
  if (value > previousNumber) delta = "increase";
  if (value < previousNumber) delta = "decrease";

  return (
      <h3 className={NumberCss.flex}>€&nbsp; 
        <motion.div layout className={NumberCss.tickerview}>
          {numArray.map((number, index) =>
            number === "." ? (
              <DecimalColumn key={index} />
            ) : (
              <NumberColumn key={index} digit={number} delta={delta} />
            )
          )}
        </motion.div>
      </h3> 
  );
}
