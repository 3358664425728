import React from 'react'
import MainCss from '../css/Main.module.css'
import HomeCss from '../css/Home.module.css';
import 'chartjs-adapter-date-fns';

function Home() {

	return (
		<div className={HomeCss.back}>
			<div className={MainCss.background}>
				<div className={MainCss.content}>
					<div className={HomeCss.padding}>
						<div className={HomeCss.title}>
							<h1 className={HomeCss.text}>
								<span>Festina Lente </span>
								is een besloten fonds voor gemene rekening.
							</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Home