import React from 'react'
import { useState } from 'react'
import NavCss from '../css/Navbar.module.css'
import { Link } from 'react-router-dom'
import { FaBars, FaTimes } from 'react-icons/fa'
import { RiBankLine } from "react-icons/ri";
import { useUserAuth } from '../context/UserAuthContext'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

function Navbar() {
	const { logOut, user } = useUserAuth()
	const [click, setClick] = useState(false)
	const navigate = useNavigate()
	const location = useLocation()
	
	const handleClick = () => {setClick(!click)}
	const handleHome = () => {setClick(false)}

	const handleLogOut = async () => {
		try {
		 	await logOut()
			navigate('/')
			setClick(!click)
		} catch (err) {
			console.log(err.message)
		}
	}
	
	return (
		<div className={click ? (NavCss.navbar_white) : (NavCss.navbar)}>
			<div className={NavCss.logo}>
				<Link to='/'><li onClick={handleHome}><RiBankLine style={{color: 'var(--text-secondary)'}}/></li></Link>
			</div>
			<ul className={click ? (NavCss.nav_menu_active) : (NavCss.nav_menu)}>
				{user && <Link to='/documenten'><li className={NavCss.nav_item} onClick={handleHome}>Documenten</li></Link>}
				{user && <Link to='/dashboard'><li className={NavCss.nav_item} onClick={handleHome}>Dashboard</li></Link>}
				{!user && location.pathname !== '/login' ? (<Link to='/login'><li className={NavCss.nav_item} onClick={handleHome}>Investor Login</li></Link>) : 
				(user ? <li className={NavCss.nav_item} onClick={handleLogOut}>Log Uit</li> : null)}
			</ul>
			{location.pathname !== '/login' ? (<div className={NavCss.hamburger} onClick={handleClick}>
				{click ? (<FaTimes size={25} style={{color: 'var(--text-secondary)'}} />) : (<FaBars size={25} style={{color: 'var(--text-secondary)'}}/>)} 
			</div>) : null}
		</div>
	)
}

export default Navbar